function path(root, sublink) {
  return `${root}${sublink}`;
}

function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  activateAccount: path(ROOTS_AUTH, '/activate/:token'),
  resetPassword: path(ROOTS_AUTH, '/reset-password')
};

// export const PATH_PAGE = {
//   comingSoon: '/coming-soon',
//   maintenance: '/maintenance',
//   pricing: '/pricing',
//   payment: '/payment',
//   about: '/about-us',
//   contact: '/contact-us',
//   faqs: '/faqs',
//   page404: '/404',
//   page500: '/500',
//   components: '/components'
// };

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  administration: {
    katalogmapping: path(ROOTS_DASHBOARD, '/administration/katalogmapping'),
    katalogmaintenance: path(ROOTS_DASHBOARD, '/administration/katalogmaintenance'),
    katalogexplorer: path(ROOTS_DASHBOARD, '/administration/katalogexplorer')
  },
  general: {
    app: path(ROOTS_DASHBOARD, '/app')
  },
  katalog: {
    catalog: {
      search: path(ROOTS_DASHBOARD, '/katalog/catalog/search/product/list'),
      list: path(ROOTS_DASHBOARD, '/katalog/catalog/list'),
      default: path(ROOTS_DASHBOARD, '/katalog/catalog/default/product/list?' + makeid(20)),
      create: path(ROOTS_DASHBOARD, '/katalog/catalog/create'),
      allproducts: path(ROOTS_DASHBOARD, '/katalog/catalog/allproducts'),
      product: {
        list: path(ROOTS_DASHBOARD, '/katalog/catalog/:id/product/list'),
        create: path(ROOTS_DASHBOARD, '/katalog/catalog/:id/product/create'),
        edit: path(ROOTS_DASHBOARD, '/katalog/catalog/:catalogId/product/:id/edit')
      }
    },
    category: {
      list: path(ROOTS_DASHBOARD, '/katalog/category/list')
    },
    externalfile: {
      create: path(ROOTS_DASHBOARD, '/katalog/externalfile/create'),
      list: path(ROOTS_DASHBOARD, '/katalog/externalfile/list'),
      edit: path(ROOTS_DASHBOARD, '/katalog/externalfile/:id/edit'),
      view: path(ROOTS_DASHBOARD, '/katalog/externalfile/:id')
    }
  },
  product: {
    list: path(ROOTS_DASHBOARD, '/product/list'),
  },
  diffusion: {
    root: path(ROOTS_DASHBOARD, '/diffusion/diffusion-channel'),
    connectChannel: path(ROOTS_DASHBOARD, '/diffusion/connect-diffusion-channel'),
    channelSettings: path(ROOTS_DASHBOARD, '/diffusion/connect-diffusion-channel/:id')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings')
  },
  externalAdminSettings: {
    root: path(ROOTS_DASHBOARD, '/admin-external-settings')
  }
};
