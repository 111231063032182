import { motion } from 'framer-motion';
// material
import { Box } from '@mui/material';
//
import { varSmallClick, varMediumClick } from './variants';

const ButtonAnimate = ({ mediumClick = false, children, sx, ...other }: any) => {
  return (
    <Box
      component={motion.div}
      whileTap="tap"
      whileHover="hover"
      variants={mediumClick ? varMediumClick : varSmallClick}
      sx={{ display: 'inline-flex', ...sx }}
      {...other}
    >
      {children}
    </Box>
  );
};

export default ButtonAnimate;
