import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useEffect, useRef } from 'react';

// Redux
import { setDriverStep, setLastStep, setStartTuto } from 'redux/slices/tutorial';
import { useDispatch, useSelector } from 'redux/store';


// DriverJs
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import '../../../assets/styles/driverJs.css';

// All steps
import { Button, Dialog, DialogContent, Stack, Typography } from '@mui/material';
import useAppStepsTuto from './AppStepsTuto';

const TutorialDriverJs = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tutorialStep = useSelector((state: any) => state.tutorial.step);
  const startTutorial = useSelector((state: any) => state.tutorial.startTuto);
  const lastStep = useSelector((state: any) => state.tutorial.lastStep);

  const steps = useAppStepsTuto();

  const driverRef = useRef(null);
  const driverObj = driver({
    popoverClass: 'driver-popover',
    showButtons: ['next'],
    nextBtnText: 'Next',
    allowClose: false,
    onPopoverRender: (popover) => {
      const firstButton = document.createElement('button');
      firstButton.innerText = 'Skip app-tour';
      firstButton.id = 'skip-app-tour';
      popover.footerButtons.appendChild(firstButton);

      firstButton.addEventListener('click', () => {
        dispatch(setDriverStep(0));
        dispatch(setStartTuto(false));
      });
    }
  });

  useEffect(() => {
    if (startTutorial) {
      driverObj;

      driverObj.setSteps(steps);
      driverObj.drive(tutorialStep);
      driverRef.current = driverObj;
    }

    return () => {
      if (driverRef.current) {
        driverRef.current.destroy();
      }
    };
  }, [tutorialStep, navigate, startTutorial]);

  const handleClickButton = () => {
    dispatch(setLastStep(false));
    navigate('/dashboard/app');
  };

  return (
    <Dialog
      open={lastStep}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: {
              xs: '80%', // Pour les écrans extra-small et small
              md: '50%' // Pour les écrans medium et plus grands
            }
          }
        },
        zIndex: 9999
      }}
    >
      <DialogContent sx={{ position: 'relative', padding: 0, bgcolor: '#0045FF' }}>
        <Stack justifyContent={'center'} alignItems={'center'} spacing={4} paddingY={8} paddingX={{ md: 0, xs: 2 }}>
          <Typography
            fontSize={{ md: 50, xs: 26 }}
            fontWeight={800}
            textAlign={'center'}
            color={'white'}
            width={{ md: 2 / 3, xs: 1 }}
          >
            {t('driverJsModal.title')}
          </Typography>

          <Typography
            fontSize={{ md: 20, xs: 14 }}
            fontWeight={600}
            textAlign={'center'}
            color={'white'}
            width={{ md: 2 / 3, xs: 1 }}
          >
            {t('driverJsModal.mainText')}
          </Typography>

          <Typography
            fontSize={{ md: 14, xs: 10 }}
            fontWeight={400}
            textAlign={'center'}
            color={'white'}
            width={{ md: 2 / 3, xs: 1 }}
          >
            {t('driverJsModal.secondText')}
          </Typography>

          <Button
            variant="contained"
            sx={{
              margin: 2,
              fontSize: '14px',
              paddingY: '13px',
              bgcolor: 'white',
              color: 'black',
              ':hover': { bgcolor: '#e5e5e5' }
            }}
            onClick={handleClickButton}
          >
            {t('driverJsModal.button')}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default TutorialDriverJs;
