/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useEffect, useReducer } from 'react';
import PropTypes, { any } from 'prop-types';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  activate: (token) => Promise.resolve({ retour: '' }),
  login: (email?, password?) => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: (email, password, firstname, lastname, company, role, website, lang, cgu, rgpd) =>
    Promise.resolve({ ko: '' }),
  resetPassword: (email) => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
  updatePassword: (input) => Promise.resolve(any)
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          // const response = await axios.get('/dashboard/katalog/catalog/list');
          const response = await axios.get('/user');
          const { user } = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email?, password?) => {
    const response = await axios.post('/user/login', {
      user: {
        email,
        password
      }
    });

    const { accessToken, user } = response.data;
    const tmpuser = user.user;
    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user: tmpuser
      }
    });
  };

  const activate = async (token) => {
    const response = await axios.post(`/user/activate`, {
      token
    });
    const { retour } = response.data;
    if (retour) {
      dispatch({ type: 'LOGOUT' });
    }
  };

  const resetPassword = async (email) => {
    await axios.post('/user/resetPassword', {
      user: {
        email
      }
    });

    dispatch({
      type: 'RESETPASSWORD',
      payload: {
        isAuthenticated: false,
        user: null
      }
    });
  };

  const register = async (email, password, firstname, lastname, company, role, website, lang, cgu, rgpd) => {
    const response = await axios.post('/user/register', {
      user: {
        email,
        password,
        firstname,
        lastname,
        company,
        role,
        website,
        lang,
        cgu,
        rgpd
      }
    });
    const { user } = response.data;
    if (user) {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    } else {
      return response.data;
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const updateProfile = async (input) => {
    const response = await axios.post('/user', {
      user: input
    });
    const { user } = response.data;
    dispatch({
      type: 'INITIALIZE',
      payload: {
        isAuthenticated: true,
        user
      }
    });
  };

  /*
  const generateApiKey = async () => {
    alert('generate');
    const response = await axios.post('/generateApiKey', {});
    const { apiKey } = response.data;
    return apiKey;
  };
  */
  const updatePassword = async (input) => {
    const response = await axios.post('/user/changePassword', {
      input
    });
    const { retour } = response.data;
    return retour;
  };
  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        activate,
        resetPassword,
        updateProfile,
        updatePassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
