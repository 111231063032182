import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  productResponse: null,
  error: null
};

const slice = createSlice({
  name: 'googleShopping',
  initialState,
  reducers: {
    addProductToGShoppingSuccess(state, action) {
      state.productResponse = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    }
  }
});

export default slice.reducer;

export function addProductToGShopping(body) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/external/googleShopping/addProductToGShopping', body);
      dispatch(slice.actions.addProductToGShoppingSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
