import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  select: null,
  description: null
};

const slice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setSelectSuccess(state, action) {
      state.select = action.payload;
    },

    setDescriptionSuccess(state, action) {
      state.description = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function setSelect(select) {
  return async (dispatch) => {
    dispatch(slice.actions.setSelectSuccess(select));
  };
}

export function setDescription(description) {
  return async (dispatch) => {
    dispatch(slice.actions.setDescriptionSuccess(description));
  };
}
