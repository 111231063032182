import { createSlice } from '@reduxjs/toolkit';
import { ProductReadOnly } from 'models/product/detail/productReadOnly';
import { ProductMultiSource } from 'models/product/detail/productMultiSource';
import axios from '../../utils/axios';
import { ProductHeader } from 'models/product/header/productHeader';

const ooPatch = require('json8-patch');

const initialState = {
  isLoading: false,
  error: false,
  catalogs: [],
  products: <ProductReadOnly[]>[],
  product: <ProductReadOnly>{},
  rowsCount: 0,
  next: null,
  previous: null,
  patch: [],
  pageSize: 25,
  currentPage: 0,
  currentCatalogId: 'all',
  productOrigin: <ProductReadOnly>{},
  productMultiSource: <ProductMultiSource>{},
  header: <ProductHeader>{},
  catalog: {
    name: 'default',
    type: 'system',
    readonly: true
  }
};

const slice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setPageSizeSuccess(state, action) {
      state.pageSize = action.payload;
    },
    setCurrentPageSuccess(state, action) {
      state.currentPage = action.payload;
    },
    // GET CATALOGS
    getRowCountSuccess(state, action) {
      state.rowsCount = action.payload;
    },
    getCatalogsSuccess(state, action) {
      state.isLoading = false;
      state.catalogs = [{ _id: 'all', name: 'All' }, ...action.payload, { _id: 'trash', name: 'Trash' }];
    },
    getCatalogSuccess(state, action) {
      state.isLoading = false;
      state.catalog = action.payload;
      state.products = action.payload.products;
    },
    getAllProductsByPageSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },
    getMultiSourceProductSuccess(state, action) {
      state.isLoading = false;
      state.productMultiSource = action.payload;
    },
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.productOrigin = action.payload.data.product;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
      state.header = action.payload.data;
      state.patch = action.payload.patch;
      if (state.patch) {
        const { doc } = ooPatch.patch(state.productOrigin, state.patch);
        state.product = doc;
      } else {
        state.product = state.productOrigin;
      }
      state.product.identifier = action.payload.data.identifier;
      state.product.created_at = action.payload.data.createdAt;
      state.product.last_request = action.payload.data.updatedAt;
      state.product.first_user_request = action.payload.data.updatedAt;
      state.product.validated = action.payload.data.validated;
      state.product.lang = action.payload.data.lang;
    },
    addCatalogSuccess(state) {
      state.isLoading = false;
    },
    resetProductsSuccess(state) {
      state.products = [];
    },
    removeProductSuccess(state) {
      state.products = [];
    },
    resetProductSuccess(state) {
      state.product = {};
    },
    resetProductMultiSourceSuccess(state) {
      state.productMultiSource = {};
    },
    setCurrentCatalogSuccess(state, action) {
      state.currentCatalogId = action.payload;
    }
  }
});

export default slice.reducer;

export function resetProducts() {
  return async (dispatch) => {
    dispatch(slice.actions.resetProductsSuccess());
  };
}

export function resetProduct() {
  return async (dispatch) => {
    dispatch(slice.actions.resetProductSuccess());
  };
}

export function getAllProductsByPage(pageSize, page, categoryFilter = []) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/products/getAllProducts?pageSize=${pageSize}&page=${page}`, {
        categoryFilter
      });
      dispatch(slice.actions.getAllProductsByPageSuccess(response.data.products));
      dispatch(slice.actions.getRowCountSuccess(response.data.rowsCount));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCatalogs() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/catalog');
      dispatch(slice.actions.getCatalogsSuccess(response.data.catalogs));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setCurrentCatalog(id) {
  return async (dispatch) => {
    dispatch(slice.actions.setCurrentCatalogSuccess(id));
  };
}

export function getCatalog(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/catalog/${id}`);
      dispatch(slice.actions.getCatalogSuccess(response.data.catalog));
      dispatch(slice.actions.getRowCountSuccess(response.data.rowsCount));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setPageSize(pageSize) {
  return async (dispatch) => {
    dispatch(slice.actions.setPageSizeSuccess(pageSize));
  };
}

export function getCatalogByPage(id, pageSize, page, categoryFilter = [], filter = '') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/catalog/${id}/allproducts?pageSize=${pageSize}&page=${page}`, {
        categoryFilter,
        filter
      });
      dispatch(slice.actions.getCatalogSuccess(response.data.catalog));
      dispatch(slice.actions.getRowCountSuccess(response.data.rowsCount));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProduct(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get<ProductReadOnly>(`/products/${id}?full=true`);
      dispatch(slice.actions.getProductSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMultiSourceProduct(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post<ProductMultiSource>(`/products/${id}/multiSourceExport`);
      dispatch(slice.actions.getMultiSourceProductSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function removeProduct(catalogId, ids) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await Promise.all(
        ids.map(async (id) => {
          const response = await axios.delete(`/catalog/${catalogId}/products/${id}`);
          return response.data;
        })
      );
      dispatch(slice.actions.removeProductSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProducts(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/catalog/${id}/products`);

      /* const tmp = await Promise.all(
        response.data.products
          .filter(async (product) => {
            const status = await axios.get(`/products/${product._id}`);
            return status.data === 'OK:serApi done';
          })
          .map(async (product) => {
            const productFull = await axios.get(`/products/${product._id}?full=true`);
            const tmp = productFull.data;

            if (productFull.data.patch) {
              const { doc } = ooPatch.patch(productFull.data.product, productFull.data.patch);
              tmp.product = doc;
            }

            return tmp;
          })
      );
      */
      dispatch(slice.actions.getProductsSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
