import { Box } from '@mui/material';
import { ReactSVG } from 'react-svg';

import MenuLogo from 'assets/menuIcons/m_logo.svg';

export default function Logo() {
  return (
    <Box>
      <ReactSVG src={MenuLogo} />
    </Box>
  );
}
