import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  currentSubscription: {},
  currentSubscriptionFull: {}
};

const slice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    }, // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getCurrentSubscriptionSuccess(state, action) {
      state.isLoading = false;
      state.currentSubscription = action.payload;
    },
    getCurrentSubscriptionFullSuccess(state, action) {
      state.isLoading = false;
      state.currentSubscriptionFull = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCurrentSubscription() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/subscriptions');
      dispatch(slice.actions.getCurrentSubscriptionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
