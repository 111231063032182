import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

import LoadingScreen from '../components/LoadingScreen';

import 'driver.js/dist/driver.css';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        {
          path: 'activate/:token',
          element: (
            <GuestGuard>
              <ActivateAccount />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> }
      ]
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to="/dashboard/app" replace />
        },
        { path: 'app', element: <Dashboard /> },
        {
          path: 'payment',
          element: <Payment />
        },
        {
          path: 'katalog',
          children: [
            { path: 'catalog/list', element: <KatalogCatalogList /> },
            { path: 'catalog/allproducts', element: <KatalogAllProducts /> },
            { path: 'catalog/allproducts/:id', element: <KatalogAllProducts /> },
            { path: 'catalog/create', element: <KatalogCatalogCreate /> },
            { path: 'catalog/:id/edit', element: <KatalogCatalogCreate /> },
            { path: 'catalog/:id/product/list', element: <KatalogProductList /> },
            { path: 'catalog/:catalogId/product/:id/edit', element: <KatalogModeSwitcher /> },
            { path: 'category/list', element: <KatalogCategoryListEditor /> },
            { path: 'externalfile/create', element: <ExternalFile /> },
            { path: 'externalfile/:id/edit', element: <ExternalFile /> },
            { path: 'externalfile/:id', element: <ExternalFile /> }
          ]
        },
        {
          path: 'administration',
          children: [
            { path: 'katalogmapping', element: <KatalogMapping /> },
            { path: 'katalogMaintenance', element: <KatalogMaintenance /> },
            { path: 'katalogExplorer', element: <KatalogExplorer /> }
          ]
        },
        {
          path: 'product',
          children: [{ path: 'list', element: <KatalogListCsv /> }]
        },
        {
          path: 'diffusion',
          children: [
            { path: 'diffusion-channel', element: <KatalogDiffusionChannel /> },
            { path: 'connect-diffusion-channel', element: <KatalogConnectChannel /> },
            { path: 'connect-diffusion-channel/:id', element: <KatalogChannelSettings /> }
          ]
        },
        {
          path: 'user',
          children: [
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> }
          ]
        },
        {
          path: 'settings',
          element: (
            <div className="step1">
              <KatalogSettings />
            </div>
          )
        },
        {
          path: 'admin-external-settings',
          element: <KatalogAdminExternalSettings />
        }
      ]
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '/', element: <Navigate to="/dashboard/app" replace /> },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ActivateAccount = Loadable(lazy(() => import('../pages/authentication/ActivateAccount')));

const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));

const KatalogCatalogList = Loadable(lazy(() => import('../pages/dashboard/KatalogCatalogList')));
const KatalogCatalogCreate = Loadable(lazy(() => import('../pages/dashboard/KatalogCatalogCreate')));
const KatalogProductList = Loadable(lazy(() => import('../pages/dashboard/KatalogProductList')));
const KatalogAllProducts = Loadable(lazy(() => import('../pages/dashboard/KatalogAllProducts')));

const KatalogCategoryListEditor = Loadable(lazy(() => import('../pages/dashboard/KatalogCategoryListEditor')));
const KatalogModeSwitcher = Loadable(lazy(() => import('../pages/product/modeSwitcher/ModeSwitcher')));

const KatalogMapping = Loadable(lazy(() => import('../pages/dashboard/KatalogMapping')));
const KatalogMaintenance = Loadable(lazy(() => import('../pages/dashboard/KatalogMaintenance')));
const KatalogExplorer = Loadable(lazy(() => import('../pages/dashboard/KatalogExplorer')));

const KatalogListCsv = Loadable(lazy(() => import('../pages/product/list/ListCsv')));

const KatalogDiffusionChannel = Loadable(lazy(() => import('../pages/diffusion/KatalogDiffusionChannel')));
const KatalogConnectChannel = Loadable(lazy(() => import('../pages/diffusion/KatalogConnectChannel')));
const KatalogChannelSettings = Loadable(lazy(() => import('../pages/diffusion/KatalogChannelSettings')));

const KatalogSettings = Loadable(lazy(() => import('../pages/settings/Settings')));
const KatalogAdminExternalSettings = Loadable(lazy(() => import('../pages/admin-external/AdminExternal')));
const ExternalFile = Loadable(lazy(() => import('../pages/dashboard/ExternalFile')));

const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

// const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const Dashboard = Loadable(lazy(() => import('../components/katalog/dashboard/Dashboard')));

const Payment = Loadable(lazy(() => import('../pages/payment/Payment')));
