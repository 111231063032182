/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  isEditMode: false,
  error: false,
  products: [],
  product: null,
  productCatalogs: [],
  sortBy: null
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // TOGGLE EDIT MODE
    toggleEditModeSuccess(state, action) {
      state.isEditMode = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    getCatalogsSuccess(state, action) {
      state.isLoading = false;
      state.productCatalogs = action.payload;
    },

    // UPDATE PRODUCT VALUE
    updateProductValueSuccess(state, action) {
      //state.isLoading = false;
      //state.product = action.payload;
    },

    // ADD PRODUCT AT CATALOG
    addProductCatalogSuccess(state, action) {
      state.isLoading = false;
    },

    // DELETE CATALO OF PRODUCT
    removeCatalogOfProductSuccess(state, action) {
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

export function updateProductValue(productID, body) {
  return async (dispatch) => {
    //dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/products/${productID}`, body);
      dispatch(slice.actions.updateProductValueSuccess(response));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCatalogsProduct(productID) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/products/${productID}/catalogs`);
      dispatch(slice.actions.getCatalogsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addProductToCatalog(productID, catalogId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/products/${productID}/addCatalog/${catalogId}`);
      dispatch(slice.actions.addProductCatalogSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteCatalogOfProduct(productID, catalogId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/products/${productID}/removeCatalog/${catalogId}`);
      dispatch(slice.actions.removeCatalogOfProductSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
