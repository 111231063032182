// routes
import { PATH_DASHBOARD } from '../../routes/paths';

import SvgIconStyle from '../../components/SvgIconStyle';
import { ReactSVG } from 'react-svg';

import SettingsIcon from 'assets/menuIcons/m_settings.svg';
import DashboardIcon from 'assets/menuIcons/m_dashboard.svg';
import FolderIcon from 'assets/menuIcons/m_folder.svg';
import ImportExportIcon from 'assets/menuIcons/m_ie.svg';
import ProductDiffusion from 'assets/menuIcons/m_diffusion.svg';
import i18next from '../../locales/i18n';

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking')
};

const sidebarConfig = [
  // CATALOGS
  // ----------------------------------------------------------------------
  {
    subheader: 'Catalogs',
    items: [
      {
        title: i18next.t('sidemenu.dashboard'),
        path: PATH_DASHBOARD.general.app,
        icon: <ReactSVG src={DashboardIcon} />
      },
      {
        title: i18next.t('sidemenu.products'),
        path: PATH_DASHBOARD.katalog.catalog.allproducts,
        icon: <ReactSVG src={FolderIcon} id="driverjs-product-sidemenu" />
      },
      {
        title: i18next.t('sidemenu.import'),
        path: PATH_DASHBOARD.product.list,
        icon: <ReactSVG src={ImportExportIcon} id="driverjs-import-sidemenu" />
      },
      {
        title: i18next.t('sidemenu.diffusion'),
        path: PATH_DASHBOARD.diffusion.root,
        icon: <ReactSVG src={ProductDiffusion} id="driverjs-diffusion-sidemenu" />
      },
      {
        title: i18next.t('sidemenu.settings'),
        path: PATH_DASHBOARD.settings.root,
        icon: <ReactSVG src={SettingsIcon} id="driverjs-settings-sidemenu" />
      }
    ]
  },
  // PRODUCTS
  // ----------------------------------------------------------------------
  {
    subheader: 'Administration',
    items: [
      // MANAGEMENT : admin
      {
        title: 'katalog mapping',
        path: PATH_DASHBOARD.administration.katalogmapping,
        icon: ICONS.user
      },
      {
        title: 'katalog maintenance',
        path: PATH_DASHBOARD.administration.katalogmaintenance,
        icon: ICONS.user
      },
      {
        title: 'katalog explorer',
        path: PATH_DASHBOARD.administration.katalogexplorer,
        icon: ICONS.user
      },
      {
        title: 'External Admin Settings',
        path: PATH_DASHBOARD.externalAdminSettings.root,
        icon: <ReactSVG src={SettingsIcon} />
      }
    ]
  }
];

export default sidebarConfig;
