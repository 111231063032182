import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import catalogReducer from './slices/catalog';
import categoryReducer from './slices/category';
import subscriptionReducer from './slices/subscription';
import ExternalFileReducer from './slices/externalfile';
import userRequestReducer from './slices/userRequest';
import stripeReducer from './slices/stripe';

import userReducer from './slices/user';
import productReducer from './slices/product';
import reportReducer from './slices/report';

import shopifyReducer from './slices/shopify';
import googleShoppingReducer from './slices/googleShopping';
import tutorialReducer from './slices/tutorial';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  catalog: catalogReducer,
  category: categoryReducer,
  subscription: subscriptionReducer,
  externalfile: ExternalFileReducer,
  userRequest: userRequestReducer,
  stripe: stripeReducer,
  user: userReducer,
  product: persistReducer(productPersistConfig, productReducer),
  report: reportReducer,
  shopify: shopifyReducer,
  googleShopping: googleShoppingReducer,
  tutorial: tutorialReducer
});

export { rootPersistConfig, rootReducer };
