/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: false,
  rootCategories: [],
  currentRootCategory: {},
  tmpRootCategory: {},

  expanded: [],
  selected: [],
  paths: []
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getRootCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.rootCategories = action.payload;
    },
    setCurrentRootCategorySuccess(state, action) {
      state.currentRootCategory = action.payload;
      state.tmpRootCategory = action.payload;
    },
    createCategorySuccess(state, action) {
      state.currentRootCategory = action.payload.root;
      state.expanded.push(action.payload.node._id);
      state.expanded.push(action.payload.node.parent);
    },
    updateCategorySuccess(state, action) {
      state.currentRootCategory = action.payload.root;
    },
    deleteCategorySuccess(state, action) {
      state.currentRootCategory = action.payload.root;
    },
    moveCategorySuccess(state, action) {
      state.currentRootCategory = action.payload.root;
    },
    setExpandedSuccess(state, action) {
      state.expanded = action.payload;
    },
    addToSelectedSuccess(state, action) {
      state.selected = [...state.selected, action.payload];
    },
    removeFromSelectedSuccess(state, action) {
      const tmp = state.selected.filter((item) => item !== action.payload);
      state.selected = tmp;
    },
    setSelectedSuccess(state, action) {
      state.selected = action.payload;
    },
    setProductCatalogCategorySuccess(state, action) {
      state.isLoading = false;
    }
  }
});

export default slice.reducer;

export function getRootCategories() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/categories/root');
      dispatch(slice.actions.getRootCategoriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setCurrentRootCategory(id) {
  return async (dispatch) => {
    const response = await axios.get(`/categories/${id}`);
    dispatch(slice.actions.setCurrentRootCategorySuccess(response.data));
  };
}

export function createCategory(currentRootId, parentId, name) {
  return async (dispatch) => {
    const response = await axios.post(`/categories/${parentId}/create`, { name, rootCategoryId: currentRootId });
    dispatch(slice.actions.createCategorySuccess(response.data));
  };
}

export function deleteCategory(currentRootId, parentId) {
  return async (dispatch) => {
    const response = await axios.delete(`/categories/${parentId}/${currentRootId}`);
    dispatch(slice.actions.deleteCategorySuccess(response.data));
  };
}

export function updateCategory(currentRootId, categoryId, name, attachedData) {
  return async (dispatch) => {
    const response = await axios.post(`/categories/${categoryId}`, {
      name,
      attachedData,
      rootCategoryId: currentRootId
    });
    dispatch(slice.actions.updateCategorySuccess(response.data));
  };
}

export function moveCategory(currentRootId, fromId, toId) {
  return async (dispatch) => {
    const response = await axios.post(`/categories/${fromId}/to/${toId}`, { rootCategoryId: currentRootId });
    dispatch(slice.actions.moveCategorySuccess(response.data));
  };
}

export function setExpanded(nodeIds) {
  return async (dispatch) => {
    dispatch(slice.actions.setExpandedSuccess(nodeIds));
  };
}

export function addToSelected(nodeId) {
  return async (dispatch) => {
    dispatch(slice.actions.addToSelectedSuccess(nodeId));
  };
}

export function removeFromSelected(nodeId) {
  return async (dispatch) => {
    dispatch(slice.actions.removeFromSelectedSuccess(nodeId));
  };
}

export function setSelected(selection) {
  return async (dispatch) => {
    dispatch(slice.actions.setSelectedSuccess(selection));
  };
}

export function setProductCatalogCategory(customProductId, catalogId, categoryIds) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/products/${customProductId}/setCategory`, {
        catalogId,
        categoryIds
      });
      dispatch(slice.actions.setProductCatalogCategorySuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
