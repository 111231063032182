import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Material
import { Box, Toolbar, IconButton, Dialog, DialogTitle, Grid, Button, useTheme } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { useMediaQuery } from '@material-ui/core';
import send from '@iconify/icons-ic/round-send';

// Hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';

// Components
import { MHidden } from '../../components/@material-extend';
import AddEan from 'components/_dashboard/katalog/AddEan';

// Store
import { useDispatch, useSelector } from 'redux/store';
import { getCurrentSubscription } from 'redux/slices/subscription';
import { getCatalog, resetProducts } from 'redux/slices/catalog';
import { setStartTuto } from 'redux/slices/tutorial';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 119;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 64;

const RootStyle = styled('div')(({ theme }) => ({
  boxShadow: '1',
  backgroundColor: alpha(theme.palette.background.default, 0), // 0.72
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`
  },
  width: `100%`,
  position: 'fixed',
  bottom: 0,
  right: 0,
  zIndex: 999
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { isCollapse } = useCollapseDrawer();

  const dispatch = useDispatch();
  const { currentSubscription } = useSelector((state: any) => state.subscription);
  const currentCatalogId: any = useSelector((state: any) => state.catalog.currentCatalogId);
  const catalog: any = useSelector((state: any) => state.catalog.catalog);

  const [usage, setUsage] = useState(0);
  const [monthLimit, setMonthLimit] = useState(0);
  const [ratio, setRatio] = useState(0);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [showAddEanButton, setShowAddEanButton] = useState(true);

  // useEffect(() => {
  //   if (pathname === '/dashboard/product/list') {
  //     setShowAddEanButton(false);
  //   } else {
  //     setShowAddEanButton(true);
  //   }
  // });

  useEffect(() => {
    dispatch(getCurrentSubscription());
  }, []);

  const [dialog, setDialog] = useState(false);

  const handleCloseDialog = async () => {
    setDialog(false);
  };

  // DIALOG PRODUCT BOX
  const [dialogAddEan, setDialogAddEan] = useState(false);
  const openDialogAddEan = () => {
    setDialogAddEan(true);
  };

  const handleCloseDialogAddEan = async () => {
    setDialogAddEan(false);
    await dispatch(resetProducts());
    dispatch(getCatalog(currentCatalogId));
  };

  useEffect(() => {
    if (JSON.stringify(currentSubscription) !== '{}') {
      setUsage(
        currentSubscription &&
          currentSubscription.counters &&
          currentSubscription.counters.length > 0 &&
          currentSubscription.counters[0].create
          ? currentSubscription.counters[0].create
          : 0
      );
      setMonthLimit(
        currentSubscription &&
          currentSubscription.subscription &&
          currentSubscription.subscription.limitation &&
          currentSubscription.subscription.limitation.limitPerMonth != 0
          ? currentSubscription.subscription.limitation.limitPerMonth
          : currentSubscription &&
            currentSubscription.subscription &&
            currentSubscription.subscription.limitation &&
            currentSubscription.subscription.limitation.limitPerDay != 0
          ? currentSubscription.subscription.limitation.limitPerDay
          : 0
      );
      setRatio((usage / monthLimit) * 100);
    }
  }, [currentSubscription]);

  const handleStartTuto = () => {
    dispatch(setStartTuto(true));
    navigate('/dashboard/settings');
  };

  return (
    <>
      <RootStyle
        sx={{
          ...(isCollapse && {
            width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
          }),
          backgroundColor: 'white'
        }}
      >
        <ToolbarStyle sx={{ backgroundColor: 'white', zIndex: 999 }}>
          <MHidden width="lgUp">
            <Box flex={2}>
              <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
                <Icon icon={menu2Fill} />
              </IconButton>
            </Box>
          </MHidden>

          {showAddEanButton === true && (
            <>
              {currentCatalogId !== 'trash' && (
                <Grid display={'flex'} alignItems={'center'} overflow={'scroll'} flexDirection={'row'} gap={1}>
                  <Button
                    variant="contained"
                    onClick={openDialogAddEan}
                    startIcon={<Icon icon={send} />}
                    sx={{ minWidth: 'fit-content' }}
                  >
                    Create product
                  </Button>

                  <Button
                    variant="contained"
                    onClick={() => navigate('/dashboard/katalog/catalog/allproducts')}
                    startIcon={<Icon icon={send} />}
                    sx={{ minWidth: 'fit-content' }}
                  >
                    Open latest product
                  </Button>

                  <Button
                    variant="contained"
                    onClick={() => navigate('/dashboard/diffusion/diffusion-channel')}
                    startIcon={<Icon icon={send} />}
                    sx={{ minWidth: 'fit-content' }}
                  >
                    Manage spread channels
                  </Button>

                  <Button variant="contained" onClick={handleStartTuto}>
                    +
                  </Button>
                </Grid>
              )}
            </>
          )}
        </ToolbarStyle>
      </RootStyle>

      <Dialog open={dialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogTitle>{t('product.createProduct')}</DialogTitle>
        <AddEan onCloseEan={handleCloseDialog} />
      </Dialog>

      <Dialog open={dialogAddEan} onClose={handleCloseDialogAddEan} fullWidth maxWidth="md" fullScreen={fullScreen}>
        <DialogTitle>Create product</DialogTitle>
        {!currentCatalogId && <AddEan onCloseEan={handleCloseDialogAddEan} />}
        {currentCatalogId === 'all' && <AddEan onCloseEan={handleCloseDialogAddEan} />}
        {currentCatalogId && currentCatalogId !== 'all' && (
          <AddEan
            onCloseEan={handleCloseDialogAddEan}
            onCloseDialog={handleCloseDialogAddEan}
            id={currentCatalogId}
            isFromModal
            catalogLang={catalog.lang}
          />
        )}
      </Dialog>
    </>
  );
}
