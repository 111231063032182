// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch_button {
    border-radius: 25px;
    font-size: 5px;
    border: none;
    min-width: 383px;
    font-size: medium;
    font-weight: 600;
    cursor: pointer;
}

/* Extra small devices */
@media only screen and (max-width: 600px) {
    .switch_button {
        min-width: 100px;
    }
}

/* Small devices */
@media only screen and (min-width: 600px) {
    .switch_button {
        min-width: 180px;
    }
}

@media only screen and (min-width: 700px) {
    .switch_button {
        min-width: 300px;
    }
}

/* Medium devices */
@media only screen and (min-width: 909px) and (max-width: 1024px) {
    .switch_button {
        min-width: 383px;
    }
}

/* Large devices */
@media only screen and (min-width: 1025px) {
    .switch_button {
        min-width: 383px;
    }
}

/* Extra large devices */
@media only screen and (min-width: 1500px) {
    .switch_button {
        min-width: 383px;
    }
}

/* Mega Extra large devices */
@media only screen and (min-width: 1900px) {
    .switch_button {
        min-width: 383px;
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/KatalogTabSwitch.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,cAAc;IACd,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;AACnB;;AAEA,wBAAwB;AACxB;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA,kBAAkB;AAClB;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA,mBAAmB;AACnB;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA,kBAAkB;AAClB;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA,wBAAwB;AACxB;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA,6BAA6B;AAC7B;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".switch_button {\n    border-radius: 25px;\n    font-size: 5px;\n    border: none;\n    min-width: 383px;\n    font-size: medium;\n    font-weight: 600;\n    cursor: pointer;\n}\n\n/* Extra small devices */\n@media only screen and (max-width: 600px) {\n    .switch_button {\n        min-width: 100px;\n    }\n}\n\n/* Small devices */\n@media only screen and (min-width: 600px) {\n    .switch_button {\n        min-width: 180px;\n    }\n}\n\n@media only screen and (min-width: 700px) {\n    .switch_button {\n        min-width: 300px;\n    }\n}\n\n/* Medium devices */\n@media only screen and (min-width: 909px) and (max-width: 1024px) {\n    .switch_button {\n        min-width: 383px;\n    }\n}\n\n/* Large devices */\n@media only screen and (min-width: 1025px) {\n    .switch_button {\n        min-width: 383px;\n    }\n}\n\n/* Extra large devices */\n@media only screen and (min-width: 1500px) {\n    .switch_button {\n        min-width: 383px;\n    }\n}\n\n/* Mega Extra large devices */\n@media only screen and (min-width: 1900px) {\n    .switch_button {\n        min-width: 383px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
