import { PATH_DASHBOARD } from '../../routes/paths';

import SettingsIcon from 'assets/menuIcons/m_settings.svg';
import DashboardIcon from 'assets/menuIcons/m_dashboard.svg';
import FolderIcon from 'assets/menuIcons/m_folder.svg';
import ProductDiffusion from 'assets/menuIcons/m_diffusion.svg';
import ImportExportIcon from 'assets/menuIcons/m_ie.svg';

import { ReactSVG } from 'react-svg';

import i18next from '../../locales/i18n';

const sidebarConfig = [
  {
    items: [
      {
        title: i18next.t('sidemenu.dashboard'),
        path: PATH_DASHBOARD.general.app,
        icon: <ReactSVG src={DashboardIcon} />
      },
      {
        title: i18next.t('sidemenu.products'),
        path: PATH_DASHBOARD.katalog.catalog.allproducts,
        icon: <ReactSVG src={FolderIcon} />
      },
      {
        title: i18next.t('sidemenu.import'),
        path: PATH_DASHBOARD.product.list,
        icon: <ReactSVG src={ImportExportIcon} />
      },
      {
        title: i18next.t('sidemenu.diffusion'),
        path: PATH_DASHBOARD.diffusion.root,
        icon: <ReactSVG src={ProductDiffusion} />
      },
      {
        title: i18next.t('sidemenu.settings'),
        path: PATH_DASHBOARD.settings.root,
        icon: <ReactSVG src={SettingsIcon} />
      }
    ]
  }
];

export default sidebarConfig;
