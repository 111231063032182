import { useNavigate } from "react-router";
import { setDriverStep, setLastStep, setOpenAddEan, setStartTuto } from "redux/slices/tutorial";
import { useDispatch } from "redux/store";

import { driver } from 'driver.js';

const useAppStepsTuto = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const driverObj = driver()

  const steps = [
    {
      element: '#driverjs-settings-sidemenu',
      popover: {
        title: '1 Before adding any product you have to create your first catalog',
        description: 'Click on settings'
      }
    },
    {
      element: '#driverjs-create-button',
      popover: {
        title: '2 Now create your first catalog',
        description: 'The catalog is here you store your products, click “New catalog” to create de new one.',
        onNextClick: () => {
          navigate('/dashboard/katalog/catalog/create');
          dispatch(setDriverStep(2));
        }
      }
    },
    {
      element: '#driverjs-catalog-name',
      popover: {
        title: '3 Add a name to your catalog',
        description: 'Click on the name input field and chose a name',
        onPopoverRender: (popover) => {
          driverObj.refresh();

          const firstButton = document.createElement('button');
          firstButton.innerText = 'Skip app-tour';
          firstButton.id = 'skip-app-tour';
          popover.footerButtons.appendChild(firstButton);

          firstButton.addEventListener('click', () => {
            dispatch(setDriverStep(0));
            dispatch(setStartTuto(false));
          });
        }
      }
    },
    {
      element: '#driverjs-langage',
      popover: {
        title: '4 Chose a langage to your catalog',
        description: 'The langage you chose is the language of your products'
      }
    },
    {
      element: '#driverjs-root-category',
      popover: {
        title: '5 Chose your root category'
      }
    },
    {
      element: '#driverjs-description',
      popover: {
        title: '6 Add a description',
        description: 'Add a description to your catalogs'
      }
    },
    {
      element: '#driverjs-save',
      popover: {
        title: '7 Congratulations ! You just need to save your catalog',
        description: 'Click on “save”',
      }
    },
    // Import Product
    {
      element: '#driverjs-import-sidemenu',
      popover: {
        title: '1 Now, let’s learn how to import your own product data.',
        description: 'Click on “Import product data”',
        onNextClick: () => {
          navigate('/dashboard/product/list');
          dispatch(setDriverStep(8));
        }
      }
    }, 
    {
      element: '#driverjs-add-data-files',
      popover: {
        title: '2 Start by adding a porduct data file',
        description: 'Click on “Add product data files”',
        onPopoverRender: (popover) => {
          driverObj.refresh();

          const firstButton = document.createElement('button');
          firstButton.innerText = 'Skip app-tour';
          firstButton.id = 'skip-app-tour';
          popover.footerButtons.appendChild(firstButton);

          firstButton.addEventListener('click', () => {
            dispatch(setDriverStep(0));
            dispatch(setStartTuto(false));
          });
        },
        onNextClick: () => {
          navigate('/dashboard/katalog/externalfile/create');
          dispatch(setDriverStep(9));
        }
      }
    }, 
    {
      element: '#driverjs-language-csv',
      popover: {
        title: '3 Chose a language',
        description: 'Click and chose your language, by default it is you account language, you can change it in settings/acount/content language',
        onPopoverRender: (popover) => {
          driverObj.refresh();

          const firstButton = document.createElement('button');
          firstButton.innerText = 'Skip app-tour';
          firstButton.id = 'skip-app-tour';
          popover.footerButtons.appendChild(firstButton);

          firstButton.addEventListener('click', () => {
            dispatch(setDriverStep(0));
            dispatch(setStartTuto(false));
          });
        },
      }
    }, 
    {
      element: '#driverjs-title-csv',
      popover: {
        title: '4 Chose a title',
        description: 'The title will be use to '
      }
    }, 
    {
      element: '#driverjs-add-csv',
      popover: {
        title: '5 Add your CSV file',
        description: 'Add your CSV file by drag and drop it in the blue zone, or add in by browsing it in you file manager'
      }
    }, 
    {
      element: '#driverjs-map-csv',
      popover: {
        title: '6 Map your column with Katalog fields',
        description: 'Click on the arrow to make to dropdown appear'
      }
    }, 
    {
      element: '#driverjs-select-fileds-csv',
      popover: {
        title: '7 Select the appropriate fields',
        description: 'Select the appropriate field for the line you are currently editing'
      }
    }, 
    {
      element: '#driverjs-save-csv',
      popover: {
        title: '8 Final step, save your work',
        description: 'You are nearly done, save your work by clicking on “Save”',
        onNextClick: () => {
          dispatch(setDriverStep(15));
          navigate('/dashboard/product/list');
        }
      }
    }, 
    {
      element: '#driverjs-your-csv',
      popover: {
        title: '9 Here is your CSV file !',
        description: 'Congratulations, here is your CSV file, you can open it by clicking on it and you can edit it by clicking on the pencil .',
        onPopoverRender: (popover) => {
          driverObj.refresh();

          const firstButton = document.createElement('button');
          firstButton.innerText = 'Skip app-tour';
          firstButton.id = 'skip-app-tour';
          popover.footerButtons.appendChild(firstButton);

          firstButton.addEventListener('click', () => {
            dispatch(setDriverStep(0));
            dispatch(setStartTuto(false));
          });
        },
        onNextClick: () => {
          dispatch(setDriverStep(16));
          navigate('/dashboard/katalog/catalog/allproducts');
        }
      }
    }, 
    {
      element: '#driverjs-add-product',
      popover: {
        title: '1 Great you have a catalog, let’s add a product in it !',
        description: 'Click on “Create product”',
        onNextClick: () => {
          dispatch(setOpenAddEan(true));
          dispatch(setDriverStep(17));
        }
      }
    }, 
    {
      element: '#driverjs-chose-catalog',
      popover: {
        title: '2 Chose a catalog',
        description: 'By default, your catalog is “default”, you can add a new catalog in settings',
        onPopoverRender: (popover) => {
          driverObj.refresh();

          const firstButton = document.createElement('button');
          firstButton.innerText = 'Skip app-tour';
          firstButton.id = 'skip-app-tour';
          popover.footerButtons.appendChild(firstButton);

          firstButton.addEventListener('click', () => {
            dispatch(setDriverStep(0));
            dispatch(setStartTuto(false));
          });
        }
      }
    }, 
    {
      element: '#driverjs-chose-language',
      popover: {
        title: '3 Chose a language',
        description: 'By default, the language is your account’s language, you can change in the account’s settings'
      }
    }, 
    {
      element: '#driverjs-add-gtin',
      popover: {
        title: '4 Add the product’s GTIN',
        description: 'Add a description to your catalogs'
      }
    }, 
    {
      element: '#driverjs-create-product',
      popover: {
        title: '5 Great, you’re near to the end !',
        description: 'Now just click on “Create product”',
        onNextClick: () => {
          dispatch(setOpenAddEan(false));
          dispatch(setDriverStep(21));
        }
      }
    }, 
    {
      element: '#driverjs-your-product',
      popover: {
        title: '6 Congratulation ! here is your product !',
        description: 'Now let’s see how to edit this product.',
      }
    },
    {
      element: '#driverjs',
      popover: {
        title: '7 Let’s edit your product !',
        description: 'Click one “Edit” to switch to edit mode.'
      }
    },
    {
      element: '#description',
      popover: {
        title: '8 Chose your source',
        description: 'Click on the katalog logo, and chose the source who suits you the most.'
      }
    },
    {
      element: '#description',
      popover: {
        title: '9 And now in your product you can see your CSV file as a avilable source',
        description: 'Select the icon folder to use your csv file as a source'
      }
    },
    {
      element: '#description',
      popover: {
        title: '1 Great you have a catalog, let’s add a product in it !',
        description: 'Click on “Create product”'
      }
    },
    {
      element: '#description',
      popover: {
        title: '2 Great you have a catalog, let’s add a product in it !',
        description: 'Click on “Create product”'
      }
    },
    {
      element: '#description',
      popover: {
        title: '3 Great you have a catalog, let’s add a product in it !',
        description: 'Click on “Create product”',
        onNextClick: () => {
          driverObj.destroy()
          dispatch(setDriverStep(0));
          dispatch(setStartTuto(false));
          dispatch(setLastStep(true));
        }
      }
    },
  ];

  return steps
}

export default useAppStepsTuto