import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  externalfiles: [],
  currentExternalFile: {},
  rowCount: 0
};

const slice = createSlice({
  name: 'externalfile',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    }, // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getExternalFilesSuccess(state, action) {
      state.isLoading = false;
      state.externalfiles = action.payload;
    },

    getExternalFileRowCountSuccess(state, action) {
      state.isLoading = false;
      state.rowCount = action.payload.data.length;
    },
    getExternalFileSuccess(state, action) {
      state.isLoading = false;
      state.currentExternalFile = action.payload;
    },
    removeExternalFileSuccess(state) {
      state.isLoading = false;
      state.currentExternalFile = null;
    }
    /*
    getCurrentSubscriptionFullSuccess(state, action) {
      state.isLoading = false;
      state.currentSubscriptionFull = action.payload;
    }
    */
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getExternalFiles() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/externalfiles');
      dispatch(slice.actions.getExternalFilesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getExternalFileRowCount(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/externalfiles/' + id + '/rowCount');
      dispatch(slice.actions.getExternalFileRowCountSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function removeExternalFile(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete('/externalfiles/' + id);
      dispatch(slice.actions.removeExternalFileSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getExternalFilePage(id, page, pageSize) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/externalfiles/' + id + '?page=' + page + '&pageSize=' + pageSize);
      dispatch(slice.actions.getExternalFileSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
