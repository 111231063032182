// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.driver-popover {
  position: absolute !important;
  inset: auto 0px 0px 0px !important;
  background-color: #0055ff;
  color: #ffffff;
  border-radius: 0 !important;
  padding: 20px;
  width: 100% !important;
  max-width: 100%;
  max-height: -moz-fit-content;
  max-height: fit-content;
}

.driver-popover .driver-popover-arrow {
  display: none;
}

.driver-popover .driver-title {
  font-size: 18px;
  font-weight: bold;
}

.driver-popover .driver-text {
  font-size: 14px;
}

.driver-popover-progress-text {
  color: white;
}

.driver-popover-footer button {
  background-color: #ffffff;
  text-shadow: none;
  font-weight: 700;
  border: 1px solid #0055ff;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
}

#skip-app-tour {
  background-color: transparent;
  text-shadow: none;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/driverJs.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,kCAAkC;EAClC,yBAAyB;EACzB,cAAc;EACd,2BAA2B;EAC3B,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,4BAAuB;EAAvB,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,gBAAgB;EAChB,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,6BAA6B;EAC7B,iBAAiB;EACjB,YAAY;AACd","sourcesContent":[".driver-popover {\n  position: absolute !important;\n  inset: auto 0px 0px 0px !important;\n  background-color: #0055ff;\n  color: #ffffff;\n  border-radius: 0 !important;\n  padding: 20px;\n  width: 100% !important;\n  max-width: 100%;\n  max-height: fit-content;\n}\n\n.driver-popover .driver-popover-arrow {\n  display: none;\n}\n\n.driver-popover .driver-title {\n  font-size: 18px;\n  font-weight: bold;\n}\n\n.driver-popover .driver-text {\n  font-size: 14px;\n}\n\n.driver-popover-progress-text {\n  color: white;\n}\n\n.driver-popover-footer button {\n  background-color: #ffffff;\n  text-shadow: none;\n  font-weight: 700;\n  border: 1px solid #0055ff;\n  border-radius: 4px;\n  padding: 8px 12px;\n  cursor: pointer;\n}\n\n#skip-app-tour {\n  background-color: transparent;\n  text-shadow: none;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
