import PropTypes from 'prop-types';
import { useZxing } from 'react-zxing';

KatalogBarcodeScanner.propTypes = {
  found: PropTypes.func
};

export default function KatalogBarcodeScanner({ found }) {
  const { ref } = useZxing({
    onDecodeResult(result) {
      found(result.getText());
      console.log(result);
    },
    onDecodeError(error) {
      console.log(error);
    },
    onError(error) {
      console.log(error);
    }
  });

  return (
    <>
      <video style={{ width: '100%' }} ref={ref} />
    </>
  );
}
