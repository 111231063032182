import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_KATALOG_BACKEND_URL
});

/*
axiosInstance.interceptors.response.use(
  (response) => {
    console.log(response);
    return response;
  },
  (error) => {
    console.log(error.response.status);
    if (error.response.status === 666) window.location.href = '/';
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);
*/

export default axiosInstance;
