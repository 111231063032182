import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';

import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';

import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Stack,
  Typography,
  LinearProgress
} from '@mui/material';
import { MHidden } from './@material-extend';
import AccountPopover from 'layouts/dashboard/AccountPopover';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'redux/store';
import { getCurrentSubscription } from 'redux/slices/subscription';

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  marginBottom: 20,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main
  }
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 50,
  height: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 0
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  isShow: PropTypes.bool,
  item: PropTypes.object
};

function NavItem({ item, isShow }) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { title, path, icon, info, children } = item;
  const isActiveRoot = path ? !!matchPath({ path, end: false }, pathname) : false;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen(!open);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>

          {isShow && (
            <>
              <ListItemText disableTypography primary={title} sx={{ textAlign: 'center' }} />
              {info && info}
              <Box
                component={Icon}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{ width: 50, height: 50, ml: 1 }}
              />
            </>
          )}
        </ListItemStyle>

        {isShow && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((item) => {
                const { title, path } = item;
                const isActiveSub = path ? !!matchPath({ path, end: false }, pathname) : false;

                return (
                  <RouterLink to={path} key={title} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItemStyle
                      sx={{
                        ...(isActiveSub && activeSubStyle)
                      }}
                    >
                      <ListItemIconStyle>
                        <Box
                          component="span"
                          sx={{
                            width: 4,
                            height: 4,
                            display: 'flex',
                            borderRadius: '50%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bgcolor: 'text.disabled',
                            transition: (theme) => theme.transitions.create('transform'),
                            ...(isActiveSub && {
                              transform: 'scale(2)',
                              bgcolor: 'primary.main'
                            })
                          }}
                        />
                      </ListItemIconStyle>
                      <ListItemText disableTypography primary={title} sx={{ textAlign: 'center' }} />
                    </ListItemStyle>
                  </RouterLink>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <>
      <MHidden width="lgUp">
        <RouterLink to={path} style={{ textDecoration: 'none', color: 'inherit' }}>
          <ListItemStyle
            sx={{
              ...(isActiveRoot && activeRootStyle),
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
            {isShow && (
              <>
                <ListItemText disableTypography primary={title} sx={{ textAlign: 'center' }} />
                {info && info}
              </>
            )}
          </ListItemStyle>
        </RouterLink>
      </MHidden>

      <MHidden width="lgDown">
        <RouterLink to={path} style={{ textDecoration: 'none', color: 'inherit' }}>
          <ListItemStyle
            sx={{
              ...(isActiveRoot && activeRootStyle),
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
            {isShow && (
              <>
                <ListItemText disableTypography primary={title} sx={{ textAlign: 'center' }} />
                {info && info}
              </>
            )}
          </ListItemStyle>
        </RouterLink>
      </MHidden>
    </>
  );
}

NavSection.propTypes = {
  isShow: PropTypes.bool,
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig, isShow = true, ...other }) {
  const { t } = useTranslation();

  const [usage, setUsage] = useState(0);
  const [monthLimit, setMonthLimit] = useState(0);
  const [ratio, setRatio] = useState(0);

  const dispatch = useDispatch();
  const { currentSubscription } = useSelector((state: any) => state.subscription);

  useEffect(() => {
    dispatch(getCurrentSubscription());
  }, []);

  useEffect(() => {
    if (JSON.stringify(currentSubscription) !== '{}') {
      setUsage(
        currentSubscription &&
          currentSubscription.counters &&
          currentSubscription.counters.length > 0 &&
          currentSubscription.counters[0].create
          ? currentSubscription.counters[0].create
          : 0
      );
      setMonthLimit(
        currentSubscription &&
          currentSubscription.subscription &&
          currentSubscription.subscription.limitation &&
          currentSubscription.subscription.limitation.limitPerMonth != 0
          ? currentSubscription.subscription.limitation.limitPerMonth
          : currentSubscription &&
            currentSubscription.subscription &&
            currentSubscription.subscription.limitation &&
            currentSubscription.subscription.limitation.limitPerDay != 0
          ? currentSubscription.subscription.limitation.limitPerDay
          : currentSubscription &&
            currentSubscription.subscription &&
            currentSubscription.subscription.initialCredit &&
            currentSubscription.subscription.initialCredit != 0
          ? currentSubscription.subscription.initialCredit
          : 0
      );
      setRatio((usage / monthLimit) * 100);
    }
  }, [currentSubscription]);

  return (
    <Box {...other}>
      {navConfig.map((list) => {
        const { subheader, items } = list;
        return (
          <List key={subheader} disablePadding>
            {/*isShow && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>*/}
            {items.map((item) => (
              <NavItem key={item.title} item={item} isShow={isShow} />
            ))}
          </List>
        );
      })}

      <Box flex={2} marginBottom={2}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            <AccountPopover />
          </Stack>

          <Stack>
            <Typography>
              <span style={{ fontWeight: 'bold', color: '#000000' }}>
                {usage}/{monthLimit} {t('navBar.datasets')}{' '}
              </span>
            </Typography>
            <LinearProgress variant="determinate" value={ratio} />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
