/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
// material
import {
  Box,
  Card,
  Button,
  FormControlLabel,
  Switch,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Backdrop,
  TextField,
  Container,
  Typography,
  TextareaAutosize,
  Dialog,
  DialogContent,
  Stack
} from '@mui/material';
import NotificationSound from 'assets/beep.wav';
import { Navigate } from 'react-router-dom';
//
import { useSnackbar, VariantType } from 'notistack';

import KatalogBarcodeScanner from './KatalogBarcodeScanner';
import axios from 'utils/axios';
import { useDispatch, useSelector } from 'redux/store';
import { getCatalogs, getProducts } from 'redux/slices/catalog';
import { getCurrentSubscription } from 'redux/slices/subscription';
import useSound from 'use-sound';

import sendDisable from 'assets/sendDisable.svg';
import sendEnable from 'assets/sendEnable.svg';

import KatalogTabSwitch from 'components/katalog/switch/KatalogTabSwitch';
import { CreateTabsEnum } from 'utils/enums/createTabsEnum';
import { useTranslation } from 'react-i18next';

type AddEanInterface = {
  onCloseEan?: (params?: any) => void;
  catalogLang?: string;
  onCloseDialog?: (params?: any) => void;
  isFromModal?: boolean;
  isFromAllProducts?: boolean;
  id?: string;
};

const AddEan = ({ onCloseEan, catalogLang, onCloseDialog, isFromModal, isFromAllProducts, id }: AddEanInterface) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [ean, setEan] = useState('');
  const [addAsync, setAddAsync] = useState(false);
  const [reachedLimit, setReachedLimit] = useState(false);
  const [available, setAvailable] = useState(0);
  const [eanMultiple, setEanMultiple] = useState('');
  const [scanner, setScanner] = useState(false);

  const [redirect] = useState(false);
  const [location] = useState('/');
  const [lang, setLang] = useState(catalogLang ? catalogLang : '');
  const [langDisabled, setLangDisabled] = useState(false);

  const { catalogs } = useSelector((state: any) => state.catalog);
  const { currentSubscription } = useSelector((state: any) => state.subscription);

  const [selectCatalogId, setSelectCatalogId] = useState(id);
  const [errorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const currentCatalogId = useSelector((state: any) => state.catalog.currentCatalogId);

  const [tabsSelected, setTabsSelected] = useState(CreateTabsEnum.SINGLE);

  useEffect(() => {
    setSelectCatalogId(currentCatalogId);
  }, [currentCatalogId]);

  useEffect(() => {
    if (catalogs != null) {
      const catalog = catalogs.find((item) => item._id === selectCatalogId);
      if (catalog != null) {
        if (catalog.name === 'default') {
          setIsDefault(true);
        }
      }
    }
  }, [selectCatalogId]);

  // SNACKBAR
  const { enqueueSnackbar } = useSnackbar();
  const showUpdateMessage = (response) => {
    let variantValue: VariantType = 'success';
    if (response.status != null) {
      switch (response.status) {
        case 500:
        case 502:
        case 503:
        case 504:
          variantValue = 'error';
          break;
        case 400:
        case 401:
        case 403:
        case 404:
          variantValue = 'error';
          break;
        default:
      }
    }
    if (tabsSelected === CreateTabsEnum.BULK) {
      if (response.data.action === 'refresh') {
        enqueueSnackbar(response.data.msg, {
          autoHideDuration: 3000,
          variant: variantValue
        });
      } else {
        enqueueSnackbar(response.data.msg, {
          autoHideDuration: 3000,
          variant: 'warning'
        });
      }
    } else {
      if (response.data._id != null) {
        enqueueSnackbar(response.data.msg, {
          autoHideDuration: 3000,
          variant: variantValue
        });
      } else {
        enqueueSnackbar(response.data.msg, {
          autoHideDuration: 3000,
          variant: 'warning'
        });
      }
    }
  };

  useEffect(() => {
    dispatch(getCatalogs());
    dispatch(getCurrentSubscription());
  }, [dispatch]);

  useEffect(() => {
    if (currentSubscription.subscription) {
      if (currentSubscription.subscription.limitation.limitPerMonth !== 0)
        setAvailable(currentSubscription.subscription.limitation.limitPerMonth);
      if (currentSubscription.subscription.limitation.limitPerDay !== 0)
        setAvailable(currentSubscription.subscription.limitation.limitPerDay);

      if (currentSubscription.subscription.initialCredit !== 0)
        setAvailable(currentSubscription.subscription.initialCredit);
      
      if (currentSubscription.counters.length > 0) {
        setAvailable(currentSubscription.counters[0].available);

        if (currentSubscription.counters[0].available <= 0) {
          setAddAsync(true);
          setReachedLimit(true);
        }
      }
    }
  }, [currentSubscription]);

  const [playEanAdded] = useSound(NotificationSound);

  const handleChangeEan = (event) => {
    setEan(event.target.value);
  };

  const handleChangeScanner = () => {
    setScanner(!scanner);
  };

  const handleChangeAddAsync = () => {
    setAddAsync(!addAsync);
  };

  const handleSendEan = async () => {
    let eanToSend = ean;
    if (tabsSelected === CreateTabsEnum.BULK)
      eanToSend = (document.getElementById('textarea') as HTMLInputElement).value;
    let catalogId = (selectCatalogId as any)._id;
    if (isFromModal && !isFromAllProducts) catalogId = id;

    let tempAsync = addAsync;
    if (eanToSend.replaceAll(' ', ';').replaceAll('\n', ';').replaceAll(',', ';').split(';').length > available) {
      alert('Not enougth credit, gtin push in Async Mode ');
      tempAsync = true;
    }

    const response = await axios.post('/products/authentified/create', {
      identifier: eanToSend,
      lang,
      catalog: catalogId,
      async: tempAsync
    });

    if (response.data.action) {
      if (isFromModal) {
        setIsLoading(true);
        setOpen(true);
        setTimeout(() => {
          if (onCloseEan) {
            onCloseEan(true);
          }
          showUpdateMessage(response);
          dispatch(getProducts(id));
        }, 1000);
      } else {
        setTimeout(() => {
          onCloseEan(true);
          //  setLocation(`/dashboard/katalog/catalog/${catalogId}/product/list`);
          //  setRedirect(true);
        }, 1000);
      }
    } else {
      showUpdateMessage(response);
      setIsLoading(false);
    }
  };

  const handleChangeLang = (event) => {
    if (!(selectCatalogId as any).lang) setLang(event.target.value);
  };

  const handleTextareaChange = (event) => {
    setEanMultiple(event.target.value);
  };

  const handleChangeCatalog = (event) => {
    setSelectCatalogId(event.target.value);
    if (event.target.value.lang) {
      setLang(event.target.value.lang);
      setLangDisabled(true);
    } else {
      setLangDisabled(false);
    }
  };

  const handleFoundEan = (eanscanned) => {
    if (tabsSelected !== CreateTabsEnum.BULK) {
      setEan(eanscanned);
      setScanner(false);
    } else {
      const temp = (document.getElementById('textarea') as HTMLInputElement).value;
      playEanAdded();
      setEanMultiple(`${temp} ${eanscanned}`);
    }
  };

  return (
    <>
      <Container>
        <Grid display="flex" justifyContent="center">
          <KatalogTabSwitch
            tabs={[
              { id: 1, value: CreateTabsEnum.SINGLE },
              { id: 2, value: CreateTabsEnum.BULK }
            ]}
            tabsSelected={tabsSelected}
            onChange={setTabsSelected}
          />
        </Grid>

        <Grid container spacing={2} justifyContent="center">
          <Dialog open={scanner} onClose={() => setScanner(false)}>
            <DialogContent>
              <Grid container>
                <Grid item xs={12} md={12} alignItems="center">
                  <Grid item xs={12} md={12}>
                    <KatalogBarcodeScanner found={handleFoundEan} />
                    <p style={{ color: 'red' }}>{errorMsg}</p>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>

          <Grid item xs={12}>
            <Grid sx={{ paddingTop: 2 }}>
              {tabsSelected === CreateTabsEnum.BULK ? (
                <>
                  <Grid container spacing={3} display={'flex'} justifyContent={'space-between'}>
                    <Grid item xs={12} md={6} marginTop={2.5}>
                      {isFromModal && !isFromAllProducts ? (
                        <FormControl sx={{ flex: 1, display: 'flex' }} disabled>
                          <InputLabel>{t('addEan.chooseCatalog')}</InputLabel>
                          <Select
                            label={t('addEan.chooseCatalog')}
                            value={id}
                            defaultValue={id}
                            id="driverjs-chose-catalog"
                          >
                            {catalogs
                              .filter((item) => !item.readonly)
                              .filter((item) => item._id !== 'all' && item._id !== 'trash')
                              .map((item) => (
                                <MenuItem key={item._id} value={item._id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <FormControl sx={{ flex: 1, display: 'flex' }}>
                          <InputLabel>{t('addEan.chooseCatalog')}</InputLabel>
                          <Select
                            label={t('addEan.chooseCatalog')}
                            onChange={handleChangeCatalog}
                            id="driverjs-chose-catalog"
                          >
                            {catalogs
                              .filter((item) => !item.readonly)
                              .filter((item) => item._id !== 'all' && item._id !== 'trash')
                              .map((item) => (
                                <MenuItem key={item._id} value={item}>
                                  {item.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      )}
                      {isFromModal && (!isDefault || isFromAllProducts) ? (
                        <Grid sx={{ paddingBottom: 2, paddingTop: 2 }}>
                          <FormControl sx={{ flex: 1, display: 'flex' }} disabled>
                            <InputLabel>{t('addEan.chooseLanguage')}</InputLabel>
                            <Select
                              label={t('addEan.chooseLanguage')}
                              value={lang}
                              defaultValue={lang}
                              id='id="driverjs-chose-language'
                            >
                              {['fr', 'en', 'es'].map((item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      ) : (
                        <Grid sx={{ paddingBottom: 2, paddingTop: 2 }}>
                          <FormControl sx={{ flex: 1, display: 'flex' }}>
                            <InputLabel>{t('addEan.chooseLanguage')}</InputLabel>
                            <Select
                              label={t('addEan.chooseLanguage')}
                              onChange={handleChangeLang}
                              value={lang}
                              disabled={langDisabled}
                              id='id="driverjs-chose-language'
                            >
                              {['fr', 'en', 'es'].map((item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      <Grid container padding={2} spacing={2} justifyContent="center">
                        <Grid item xs={4} md={12} sx={{ marginBottom: 5 }} />
                        <Grid display={'flex'} flexDirection={'row'}>
                          <FormControlLabel
                            control={<Switch checked={scanner} />}
                            onChange={handleChangeScanner}
                            label={t('addEan.scanMode')}
                          />
                          <Stack direction={'row'}>
                            <FormControlLabel
                              sx={{ margin: 0 }}
                              control={<Switch checked={addAsync} />}
                              onChange={handleChangeAddAsync}
                              label={''}
                              disabled={reachedLimit}
                            />
                            <Typography fontSize={12} whiteSpace={'pre-wrap'} textAlign={'center'}>
                              {t('addEan.asyncMode')}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container>
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                          <Typography textAlign="center" textTransform="uppercase" fontWeight="bold" color="primary">
                            {t('addEan.or')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Card
                            sx={{
                              p: 2,
                              border: 1,
                              borderColor: '#dbdfe3',
                              boxShadow: 'none',
                              width: '100%'
                            }}
                          >
                            <TextareaAutosize
                              minRows={10}
                              style={{ width: '100%', border: 'none' }}
                              value={eanMultiple}
                              onChange={handleTextareaChange}
                              id="textarea"
                            />
                          </Card>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                          {isLoading && (
                            <>
                              <Backdrop open={open}>
                                <CircularProgress />
                              </Backdrop>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item justifyContent="center" textAlign="center" xs={12}>
                      {(isFromModal || isFromAllProducts) && (
                        <Button variant="contained" onClick={onCloseDialog}>
                          {t('addEan.cancel')}
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        sx={{
                          marginBottom: 3
                        }}
                        disabled={!lang || !selectCatalogId}
                        onClick={handleSendEan}
                      >
                        {!lang && !selectCatalogId ? (
                          <Grid item sx={{ marginRight: 1 }}>
                            <img src={sendDisable} />
                          </Grid>
                        ) : (
                          <Grid item sx={{ marginRight: 1 }}>
                            <img src={sendEnable} />
                          </Grid>
                        )}
                        {t('addEan.createBulkProducts')}
                      </Button>

                      {redirect && <Navigate to={location} />}
                    </Grid>
                  </Grid>
                </>
              ) : (
                <div>
                  <Grid container justifyContent="center">
                    <Stack direction={'column'} alignItems={'center'} gap={1} mb={8}>
                      <Typography fontSize={22} fontWeight={'bold'} textAlign={'center'}>
                        it's easy, you just need to scan your barcode !
                      </Typography>
                      <Typography fontSize={14} color={'GrayText'}>
                        Or fill manually the GTIN
                      </Typography>
                    </Stack>

                    <Grid item xs={12} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                      {isFromModal && !isFromAllProducts ? (
                        <FormControl sx={{ flex: 1, display: 'flex' }} disabled>
                          <InputLabel>{t('addEan.chooseCatalog')}</InputLabel>
                          <Select
                            label={t('addEan.chooseCatalog')}
                            value={id}
                            defaultValue={id}
                            id="driverjs-chose-catalog"
                          >
                            {catalogs
                              .filter((item) => !item.readonly)
                              .map((item) => (
                                <MenuItem key={item._id} value={item._id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <Grid width={{ md: '45%', xs: '80%' }}>
                          <FormControl sx={{ flex: 1, display: 'flex' }}>
                            <InputLabel>{t('addEan.chooseCatalog')}</InputLabel>
                            <Select
                              label={t('addEan.chooseCatalog')}
                              onChange={handleChangeCatalog}
                              id="driverjs-chose-catalog"
                            >
                              {catalogs
                                .filter((item) => !item.readonly)
                                .filter((item) => item._id !== 'all' && item._id !== 'trash')
                                .map((item) => (
                                  <MenuItem key={item._id} value={item}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      {isFromModal && (!isDefault || isFromAllProducts) ? (
                        <Grid sx={{ paddingBottom: 2, paddingTop: 2 }}>
                          <FormControl sx={{ flex: 1, display: 'flex' }} disabled>
                            <InputLabel>{t('addEan.chooseLanguage')}</InputLabel>
                            <Select
                              label={t('addEan.chooseLanguage')}
                              value={lang}
                              defaultValue={lang}
                              id='id="driverjs-chose-language'
                            >
                              {['fr', 'en', 'es'].map((item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      ) : (
                        <Grid sx={{ paddingBottom: 2, paddingTop: 2 }} width={{ md: '45%', xs: '80%' }}>
                          <FormControl sx={{ flex: 1, display: 'flex' }}>
                            <InputLabel>{t('addEan.chooseLanguage')}</InputLabel>
                            <Select
                              label={t('addEan.chooseLanguage')}
                              onChange={handleChangeLang}
                              value={lang}
                              disabled={langDisabled}
                              id="driverjs-chose-language"
                            >
                              {['fr', 'en', 'es'].map((item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      {isFromModal && (!isDefault || isFromAllProducts) ? (
                        <Grid sx={{ paddingBottom: 2 }}>
                          <FormControl sx={{ flex: 1, display: 'flex' }}>
                            <TextField
                              label="GTIN"
                              variant="outlined"
                              value={ean}
                              onChange={handleChangeEan}
                              id="driverjs-add-gtin"
                            />
                          </FormControl>
                        </Grid>
                      ) : (
                        <Grid sx={{ paddingBottom: 2 }} width={{ md: '45%', xs: '80%' }}>
                          <FormControl sx={{ flex: 1, display: 'flex' }}>
                            <TextField
                              label="GTIN"
                              variant="outlined"
                              value={ean}
                              onChange={handleChangeEan}
                              id="driverjs-add-gtin"
                            />
                          </FormControl>
                        </Grid>
                      )}

                      <Grid container padding={6} spacing={2} justifyContent="center">
                        <Grid item xs={4} md={12} />
                        <Grid display={'flex'} flexDirection={'row'}>
                          <FormControlLabel
                            control={<Switch checked={scanner} />}
                            onChange={handleChangeScanner}
                            label={t('addEan.scanMode')}
                          />
                          <Stack direction={'row'}>
                            <FormControlLabel
                              sx={{ margin: 0 }}
                              control={<Switch checked={addAsync} />}
                              onChange={handleChangeAddAsync}
                              label={''}
                              disabled={reachedLimit}
                            />
                            <Typography fontSize={12} whiteSpace={'pre-wrap'} textAlign={'center'}>
                              {t('addEan.asyncMode')}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {!isFromModal && !isFromAllProducts && (
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                      <Button
                        variant="contained"
                        onClick={handleSendEan}
                        disabled={!lang || !selectCatalogId}
                        sx={{ paddingY: 1.5, paddingX: 2, mt: 4 }}
                        id="driverjs-create-product"
                      >
                        {t('addEan.create')}
                      </Button>
                    </Grid>
                  )}

                  <Grid sx={{ paddingBottom: 4 }}>
                    <Box sx={{ paddingTop: 2 }} textAlign="right">
                      {isLoading === true && (isFromModal || isFromAllProducts) ? (
                        <>
                          <Backdrop open={open}>
                            <CircularProgress />
                          </Backdrop>
                        </>
                      ) : (
                        <>
                          {(isFromModal || isFromAllProducts) && (
                            <div>
                              <Button variant="contained" onClick={onCloseDialog}>
                                {t('addEan.cancel')}
                              </Button>
                              <Button
                                variant="contained"
                                onClick={handleSendEan}
                                sx={{ marginLeft: 1, paddingY: 1.5, paddingX: 2 }}
                                disabled={!lang || !selectCatalogId}
                              >
                                {t('addEan.create')}
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                    </Box>
                    {redirect && <Navigate to={location} />}
                  </Grid>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AddEan;
