// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          '&:hover': {
            boxShadow: 'none'
          }
        },
        sizeLarge: {
          height: 48
        },
        // contained
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400]
          }
        },
        // containedPrimary: {
        //   boxShadow: theme.customShadows.primary
        // },
        // containedInfo: {
        //   boxShadow: theme.customShadows.info
        // },
        // containedSuccess: {
        //   boxShadow: theme.customShadows.success
        // },
        // containedWarning: {
        //   boxShadow: theme.customShadows.warning
        // },
        // containedError: {
        //   boxShadow: theme.customShadows.error
        // },
        // outlined
        outlined: {
          border: `1px solid #00F0C3`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        },
        outlinedSecondary: {
          border: `1px solid #00F0C3`
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        }
      }
    }
  };
}
