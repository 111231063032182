/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';

import { Tab, Tabs, ThemeProvider, createTheme, Box } from '@mui/material';
import 'assets/styles/KatalogTabSwitch.css';
import { capitalCase } from 'change-case';

type KatalogTabSwitchInterface = {
  tabs?: any;
  tabsSelected?: string;
  onChange?: (params?: any) => void;
  fullwidth?: boolean;
};

const KatalogTabSwitch = ({ tabs, tabsSelected, onChange, fullwidth }: KatalogTabSwitchInterface) => {
  const fontColorEnable = 'white !important';
  const fontColorDisable = '#0045FF !important';

  const [currentTab, setCurrentTab] = useState(tabsSelected);

  const customTabs = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          flexContainer: {
            justifyContent: 'center'
          },
          indicator: {
            backgroundColor: '#0045ff',
            height: '100%',
            zIndex: 1,
            borderRadius: '25px',
            transitionDuration: '100ms'
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            zIndex: 2,
            width: '100%',
            fontWeight: 'bold'
          }
        }
      }
    }
  });

  const customTabsSmallDevice = createTheme({
    components: {}
  });

  const handleClickButton = async (tab) => {
    onChange(tab.value);
  };

  const handleClickButtonSmallDevice = async (e, value) => {
    onChange(value);
  };

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  // For small screens
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.matchMedia('(max-width: 768px)').matches);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  return !isSmallScreen ? (
    <Box sx={{ marginBottom: 5, marginTop: 2, textAlign: 'center' }}>
      <ThemeProvider theme={customTabs}>
        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            backgroundColor: '#e6edff',
            borderRadius: 20,
            width: fullwidth ? '100%' : 'initial'
          }}
        >
          {tabs.map((tab) => (
            <Tab
              className="switch_button"
              onClick={async () => await handleClickButton(tab)}
              sx={{
                color: tabsSelected === tab.value ? fontColorEnable : fontColorDisable
              }}
              key={tab.value}
              value={tab.value}
              label={capitalCase(tab.value)}
            />
          ))}
        </Tabs>
      </ThemeProvider>
    </Box>
  ) : (
    <Box sx={{ textAlign: 'center' }}>
      <ThemeProvider theme={customTabsSmallDevice}>
        <Tabs
          value={tabsSelected}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={async (e, value) => await handleClickButtonSmallDevice(e, value)}
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={capitalCase(tab.value)} />
          ))}
        </Tabs>
      </ThemeProvider>
    </Box>
  );
};

export default KatalogTabSwitch;
