import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  subscriptionPlansList: [],
  subscriptionPricesList: [],
  currentSubscription: null,
  currentPlan: null,
  paymentIntentBody: {},
  customerData: null,
  clientSecret: null,
  paymentMethod: null,
  invoices: [],
  error: {}
};

const slice = createSlice({
  name: 'stripe',
  initialState,
  reducers: {
    createPaymentIntentSuccess(state, action) {
      state.clientSecret = action.payload.clientSecret;
    },
    getAllSubscriptionPlansSuccess(state, action) {
      if (action?.payload) {
        action.payload.data.sort((a, b) => {
          return parseInt(a.metadata?.order, 10) > parseInt(b.metadata?.order, 10)
            ? 1
            : parseInt(b.metadata?.order, 10) > parseInt(a.metadata?.order, 10)
            ? -1
            : 0;
        });
      }

      state.subscriptionPlansList = action.payload;
    },
    getAllSubscriptionPricesSuccess(state, action) {
      state.subscriptionPricesList = action.payload;
    },
    setPaymentIntentBodySuccess(state, action) {
      state.paymentIntentBody = action.payload;
    },
    getStripeCustomerSuccess(state, action) {
      state.customerData = action.payload;
    },
    createStripeCustomerSuccess(state, action) {
      state.customerData = action.payload;
    },
    createSubscriptionSuccess(state, action) {
      state.currentSubscription = action.payload;
    },
    getSubscriptionByCustomerIDSuccess(state, action) {
      state.currentSubscription = action.payload;
    },
    updateSubscriptionSuccess(state, action) {
      state.currentSubscription = action.payload;
    },
    getInvoicesByCustomerIDSuccess(state, action) {
      state.invoices = action.payload;
    },
    setCurrentPlanSuccess(state, action) {
      state.currentPlan = action.payload;
    },
    getStripeCustomerPaymentMethodSuccess(state, action) {
      state.paymentMethod = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    }
  }
});

export default slice.reducer;

// CUSTOMER
export function getStripeCustomer(customerID) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/stripe/getStripeCustomer/${customerID}`);
      dispatch(slice.actions.getStripeCustomerSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createStripeCustomer(body) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/stripe/createStripeCustomer', body);
      dispatch(slice.actions.createStripeCustomerSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// PAYMENT
export function createPaymentIntent(body) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/stripe/createPaymentIntent', body);
      dispatch(slice.actions.createPaymentIntentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// PRODUCTS
export function getAllSubscriptionPlans() {
  return async (dispatch) => {
    try {
      const response = await axios.get('/stripe/getAllSubscriptionPlans');
      dispatch(slice.actions.getAllSubscriptionPlansSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllSubscriptionPrices() {
  return async (dispatch) => {
    try {
      const response = await axios.get('/stripe/getAllSubscriptionPrices');
      dispatch(slice.actions.getAllSubscriptionPricesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setPaymentIntentBody(body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setPaymentIntentBodySuccess(body));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createSubscription(priceID, customerID) {
  const body = {
    priceID,
    customerID
  };

  return async (dispatch) => {
    try {
      const response = await axios.post('/stripe/createSubscription', body);
      dispatch(slice.actions.createSubscriptionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateSubscription(subscriptionID, priceID, subscriptionItemID) {
  const body = {
    subscriptionID,
    priceID,
    subscriptionItemID
  };

  return async (dispatch) => {
    try {
      const response = await axios.post('/stripe/updateSubscription', body);
      dispatch(slice.actions.updateSubscriptionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSubscriptionByCustomerID(customerID) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/stripe/getSubscriptionByCustomerID/${customerID}`);
      dispatch(slice.actions.getSubscriptionByCustomerIDSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStripeCustomerPaymentMethod(customerID) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/stripe/getStripeCustomerPaymentMethod/${customerID}`);
      dispatch(slice.actions.getStripeCustomerPaymentMethodSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getInvoicesByCustomerID(customerID) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/stripe/getInvoicesByCustomerID/${customerID}`);
      dispatch(slice.actions.getInvoicesByCustomerIDSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setCurrentPlan(plan) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setCurrentPlanSuccess(plan));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
