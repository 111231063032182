import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  startTuto: false,
  step: 0,
  openAddEan: false,
  lastStep: false,
  openAddEan: false
};

const slice = createSlice({
  name: 'tutorial',
  initialState,
  reducers: {
    setStartTuto: (state, action) => {
      state.startTuto = action.payload
    },
    setDriverStep: (state, action) => {
      state.step = action.payload
    },
    setOpenAddEan: (state, action) => {
      state.openAddEan = action.payload
    },
    setLastStep: (state, action) => {
      state.lastStep = action.payload
    }
  }
});

export const { setStartTuto, setDriverStep, setOpenAddEan, setLastStep } = slice.actions;

export default slice.reducer;