import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  productResponse: null,
  error: null
};

const slice = createSlice({
  name: 'shopify',
  initialState,
  reducers: {
    addProductToShopifySuccess(state, action) {
      state.productResponse = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    }
  }
});

export default slice.reducer;

export function addProductToShopify(body) {
  return async (dispatch) => { 
    try {
      const response = await axios.post('/external/shopify/addProductToShopify', body);
      dispatch(slice.actions.addProductToShopifySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
